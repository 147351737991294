<template>
	<div id="paxExpress" class="pax_global">
		<div class="tabs mb-3 d-lg-none">
			<ul class="nav nav-tabs nav-justified">
				<li class="nav-item"><a class="nav-link" :class="{'active' : step < 0, }" @click="openMenu"><b><font-awesome-icon :icon="['fas', 'home']"/></b><span>{{ $t('pax.accueil') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 1, 'active': step === 1 || step === 2}" @click="goToStep(1)"><b>1</b><span>{{ $t('pax.etalons') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 3, 'active': step === 3}" @click="goToStep(3)"><b>2</b><span>{{ $t('pax.juments') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 4, 'active': step === 4}" @click="goToStep(4)"><b>3</b><span>{{ $t('pax.comparaison') }}</span></a></li>
			</ul>
		</div>
		
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col bstallion" v-show="step === 1 || ((isLg || isXl) && step === 3)">
					<Stallions 
						ref="stallions" 
						:stallions_selected.sync="stallions_selected" 
						:stallions_available.sync="stallions_available" 
						:step_label.sync="step_label" 
						:stallions_preselected="stallions_preselected"
						:filtre_categories.sync="filtre_categories"
						:filtre_prix.sync="filtre_prix"
					/>
				</div>
				<div class="col" v-show="step == 2">
					<Selection :force_refresh="force_refresh" :stallions.sync="stallions_selected" :step_label.sync="step_label"></Selection>
				</div>
				<div class="col-12 col-lg-4 bmare" v-show="step === 3">
					<Mare :force_refresh="force_refresh" :mare="mare" :criteres.sync="criteres" :stallions="stallions_selected"></Mare>
					<ResultCompatibiliteH v-if="(!isLg && !isXl)" :displayCompatibilite.sync="displayCompatibilite" :stallions="stallions_selected" :stallions_available="stallions_available" :criteres="criteres" :step_label.sync="step_label" :stallions_comparaison.sync="stallions_comparaison" :mare="mare" :suggestion.sync="suggestion"></ResultCompatibiliteH>
				</div>
				<div class="col-12 col-lg-4" v-if="(isLg || isXl) && step === 3">
					<ResultCompatibiliteHFull :displayCompatibilite.sync="displayCompatibilite" :stallions="stallions_selected" :stallions_available="stallions_available" :criteres="criteres" :step_label.sync="step_label" :stallions_comparaison.sync="stallions_comparaison" :mare="mare" :suggestion.sync="suggestion"></ResultCompatibiliteHFull>
				</div>
				<div class="col-12" v-show="step === 4">
					<ResultCompatibiliteV :stallions="stallions_comparaison" :stallion_id.sync="stallion_id" :step.sync="step" :criteres="criteres" :mare="mare" :step_label.sync="step_label" :show="step === 4"></ResultCompatibiliteV>
				</div>
				<div class="col-12" v-if="step === 5">
					<PaxGraphique :step.sync="step" :mare="mare" :stallions="stallions_comparaison" :stallion_id="stallion_id" :step_label.sync="step_label"></PaxGraphique>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Constants from 'Constants'
	import Navigation from '@/mixins/Navigation.js'
	import PaxMixin from '@/mixins/Pax.js'
	import Config from '@/mixins/Config.js'
	import Window from '@/mixins/Window.js'
	import Horse from '@/mixins/Horse.js'
	import _uniqueId from 'lodash/uniqueId'

	export default {
		name: 'HorsePax',
		props: ['horse_id'],
		mixins: [Navigation, PaxMixin, Config, Window, Horse],
		data () {
			return {
				stallions_selected: [],
				stallions_available: [],
				criteres: [],
				mare: {},
				displayCompatibilite: false,
				force_refresh: null,
				stallion_id: null,
				step: 3,
				step_label: 'mare_list',
				steps: {'stallions_selected': 3, 'stallions': 1, 'mare_list': 3, 'comparaison_V': 4, 'pax_graphique': 5, 'country': 6},
				stallions_preselected: [],
				from_preselected: false,
				stallions_comparaison: [],
				suggestion: [],
				filtre_categories: [],
				filtre_prix: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				document.querySelector('html').classList.remove('no_scroll')
				this.mare = await this.getHorseById(this.horse_id)
				this.stallions_selected = []
				this.displayCompatibilite = false
				this.criteres = []
				this.stallions_preselected = []
				// au reload permet de mettre à jour les critères à l'étape pax
				this.force_refresh = _uniqueId()

				if(this.$route.params.stallion_ids) {
					this.stallions_preselected = this.$route.params.stallion_ids.split(',').map(s => parseInt(s))
					this.from_preselected = true
				}
				else {
					const stallions = await this.getMarePanier(this.horse_id)
					if(stallions) {
						this.from_preselected = true
						this.stallions_preselected = stallions.map(stallion => stallion.panier_stallion)
					}
				}
				if(this.$route.params.filtre_categories) {
					this.filtre_categories = this.$route.params.filtre_categories.split(',')
				}
				if(this.$route.params.filtre_prix) {
					this.filtre_prix = this.$route.params.filtre_prix.split(',')
				}

				let that = this
				history.pushState(null, document.title, location.href)
				window.addEventListener('popstate', function(event) {
					history.pushState(null, null, window.location.pathname);

					if(that.step == 5) {
						that.step_label = 'comparaison_V'
					}
					else if(that.step == 4) {
						that.step_label = 'stallions_selected'
					}
					else if(that.step == 3) {
						const ids = that.stallions_selected.map(stallion => stallion.horse_id)
						that.$router.push({ name: 'pax', params: { stallion_ids: ids.join(',') }})
					}
				})
			},

			goToHome() {
				this.init_component()
			},

			goToStep(step_to_go) {
				if(step_to_go < this.step) {
					this.step_label = Object.keys(this.steps).find(key => this.steps[key] === step_to_go)
				}
			},

			openMenu() {
				this.$store.commit('nav/open')
			}
		},

		watch: {
			step_label(val) {
				if(val == 'stallions_selected' && this.from_preselected) {
					this.from_preselected = false
					this.step = this.steps['mare_list']
				}
				else if(val == 'country') {
					this.$router.push({ name: 'pax', params: { mare_id: this.horse_id }})
				}
				else {
					this.step = this.steps[val]
				}
			},
			'suggestion': {
				deep: true,
				handler(val) {
					this.step_label = 'stallions_selected'
					this.stallions_selected = this.stallions_selected.concat(val)
				}
			}
		},

		components: {
			Stallions : () => import('@/components/Pax/Stallions'),
			Mare : () => import('@/components/Pax/Mare'),
			SelectionMare : () => import('@/components/Pax/SelectionMare'),
			Selection : () => import('@/components/Pax/Selection'),
			ResultCompatibiliteH : () => import('@/components/Pax/ResultCompatibiliteH'),
			ResultCompatibiliteHFull : () => import('@/components/Pax/ResultCompatibiliteHFull'),
			ResultCompatibiliteV : () => import('@/components/Pax/ResultCompatibiliteV'),
			PaxGraphique : () => import('@/components/Pax/PaxGraphique'),
			ChoixLangue: () => import('@/components/Pax/ChoixLangue'),
			ChoixCountry: () => import('@/components/Pax/ChoixCountry')
		}
	}

</script>